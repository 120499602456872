import React from 'react';

import './Button.component.scss';

interface ButtonProps {
  children: React.ReactNode
  onClick: () => void
}

export const Button: React.FC<ButtonProps> = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className={'button'}
  >
    {children}
  </button>
);