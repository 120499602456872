import { Color } from "../../../models/color";

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ThemeColors {
  '--theme-background': Color;
  '--theme-background-break': Color;
  '--theme-primary': Color;
  '--theme-primary-oposite': Color;
  '--theme-secondary': Color;
  '--theme-third': Color;
  '--theme-title': Color;
  '--theme-paragraph': Color;
  '--theme-accessories': Color;
  '--theme-codeBg': Color;
  '--theme-codeContent': Color;
  '--theme-quote': Color;
  '--theme-box': Color;
  '--theme-border': Color;
}