import React from 'react';

import { StoreContext } from '../../model/store.model';
import { WindowResolution, DeviceType } from './window.model';
import { isDeviceRes } from './window.helper';

interface WindowState {
  resolution: WindowResolution | undefined;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isHd: boolean;
}

interface WindowActions {
}

type WindowContext = StoreContext<WindowState, WindowActions>;

const initialState = {
  state: {
    resolution: undefined,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  },
  actions: undefined,
}

const WindowContext = React.createContext<WindowContext>(initialState as WindowContext);

export const WindowProvider: React.FC = ({ children }) => {
  const [resolution, setResolution] = React.useState<WindowResolution>();
  const [isMobile, setMobileState] = React.useState(false);
  const [isTablet, setTabletState] = React.useState(false);
  const [isDesktop, setDesktopState] = React.useState(false);
  const [isHd, setHdState] = React.useState(false);

  const onResize = () => {
    setResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  React.useEffect(() => {
    setResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  React.useEffect(() => {
    setMobileState(isDeviceRes(resolution, DeviceType.MOBILE))
    setTabletState(isDeviceRes(resolution, DeviceType.TABLET))
    setDesktopState(isDeviceRes(resolution, DeviceType.DESKTOP))
    setHdState(isDeviceRes(resolution, DeviceType.HD))
  }, [resolution])

  return (
    <WindowContext.Provider value={{
      state: {
        resolution,
        isMobile,
        isTablet,
        isDesktop,
        isHd,
      },
      actions: {},
    }}>
      { children }
    </WindowContext.Provider>
  )
};

export const useWindow = () => React.useContext(WindowContext);
