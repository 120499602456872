export enum Color {
  LIGHT_BG = '#F7F7F7',
  LIGHT_VIOLET = '#E6CCFE',
  INTENSIVE_VIOLET = '#9E25FC',
  MIDDLE_VIOLET = '#420C7C',
  DARK_VIOLET = '#230341',
  LIGHTEST_GRAY = '#F7F7F7',
  LIGHT_GRAY = '#F0F0F0',
  SILVER = '#EDEDED',
  MIDDLE_GRAY = '#8E8E8E',
  DARK_GRAY = '#272727',
  WHITE = '#FFF',
  MINT = '#17E0C1',
  ERROR = '#F77A7A',
}