import classNames from 'classnames';
import React from 'react';
import { Link } from 'gatsby';

import { NavProps } from '../Nav/Nav.component';
import { Text, TextFontSize } from '../Text';

import './NavHorizontal.component.scss';

interface NavHorizontal extends NavProps {}

export const NavHorizontal: React.FC<NavHorizontal> = ({
  navItems,
  activeRoute,
}) => (
  <div className={'nav-horizontal'}>
    <ul className={'nav-horizontal__list'}>
      {navItems.map(({ label, path }) => (
        <li
          key={`nav-horizontal-${label}`}
          className={classNames('nav-horizontal__item', {
          'nav-horizontal__item--active': activeRoute === path,
          })}
        >
          <Link to={path}>
            <Text
              size={TextFontSize.regular}
            >
              {label}
            </Text>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
