import React from 'react';

import { GridResolutions } from '../../grid.model';

import './GridColumn.component.scss';

type GridColumnValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridColumnProps = GridResolutions<GridColumnValues> & { spacing?: string, children: React.ReactNode };

export const GridColumn: React.FC<GridColumnProps> = ({
  mobile,
  tablet,
  desktop,
  spacing,
  children,
}) => (
  <div
    className={'grid-column'}
    style={{
      '--grid-column-mobile': mobile,
      '--grid-column-tablet': tablet,
      '--grid-column-desktop': desktop,
      '--grid-column-spacing': spacing,
    } as React.CSSProperties}
  >
    {children}
  </div>
);