import React from 'react';

import { Text, TextFontSize } from '../Text';
import { Color } from '../../models/color';

import logo from '../../images/logo_black.svg'

import './Footer.component.scss';
import { Link } from 'gatsby';

export const Footer: React.FC = () => (
  <div className={'footer'}>
    <ul className='footer__list'>
      <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
      <li><Link to={'/cookie-policy'}>Cookie Policy</Link></li>
      <li><Link to={'/newsletter-policy'}>Newsletter Policy</Link></li>
    </ul>
    <div className={'footer__copyright'}>
      <Text
        color={Color.DARK_GRAY}
        size={TextFontSize.small}
      >
        Copyright 2022 by
      </Text>
      <img
        className={'footer__logo'}
        src={logo}
      />
    </div>
  </div>
);