import React from 'react';

import { Hamburger } from '../Hamburger/Hamburger.component';
import { NavHorizontal } from '../NavHorizontal';
import { NavVertical } from '../NavVertical';

import './Nav.component.scss';

interface NavItem {
  label: string;
  path: string;
}

export interface NavProps {
  activeRoute: string;
  isMainPage: boolean;
  navItems: NavItem[],
}

export const Nav: React.FC<NavProps> = ({ activeRoute, isMainPage, navItems }) => {
  const [isVerticalNavOpen, setVerticalNavState] = React.useState(false);

  return (
    <div className={'nav'}>
      <div className={'nav__hamburger'}>
        <Hamburger 
          theme={isMainPage ? 'default' : 'color'}
          onClick={() => setVerticalNavState(true)}
        />
      </div>
      {isVerticalNavOpen && (
        <div className={'nav__vertical'}>
          <NavVertical
            activeRoute={activeRoute}
            navItems={navItems}
            isMainPage={isMainPage}
            onClose={() => setVerticalNavState(false)}
          />
        </div>
      )}
      <div className={'nav__horizontal'}>
        <NavHorizontal
          activeRoute={activeRoute}
          navItems={navItems}
          isMainPage={isMainPage}
        />
      </div>
    </div>
  );
};