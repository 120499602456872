import { Link } from "gatsby"
import React from "react"
import { storage } from "../../helpers/storage.helper"

import { Button } from "../Button"

import "./Cookies.component.scss"

const COOKIES_KEY = "DISPLAYED_COOKIES"

export const Cookies: React.FC = () => {
  const [isCookiesNoteVisible, setCookiesNoteVisibility] = React.useState(false)

  // NOTE: 30 days expiration
  const isExpired = (timestamp: string) =>
    Date.now() - parseInt(timestamp) >= 1000 * 60 * 60 * 24 * 30

  const handleCookiesOnLoad = () => {
    const lastSeenCookieTimestamp = storage.get(COOKIES_KEY)
    const isCookieExpired = isExpired(lastSeenCookieTimestamp)

    if (!lastSeenCookieTimestamp || isCookieExpired) {
      setCookiesNoteVisibility(true)
    }
  }

  const handleSetCookies = () => {
    storage.set(COOKIES_KEY, `${Date.now()}`)
    setCookiesNoteVisibility(false)
  }

  React.useEffect(() => {
    handleCookiesOnLoad()
  }, [])

  return isCookiesNoteVisible ? (
    <div className={"cookies"}>
      <div className={"cookies__content"}>
        <div className={"cookies__text"}>
          We use cookies for data analysis. By using hello-js.com, you agree to
          our <Link to={'/privacy-policy'}>privacy policy</Link> and <Link to={'/cookie-policy'}>cookie policy</Link>.
        </div>
        <Button onClick={handleSetCookies}>I agree</Button>
      </div>
    </div>
  ) : null
}
