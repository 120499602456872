export const storage = {
  get: (key: string): any => {
    const item = localStorage.getItem(key)
    return item
      ? JSON.parse(item)
      : null
  },
  set: (key: string, value: string) => {
    if (typeof value !== 'string') {
      JSON.stringify(value)
    }
    localStorage.setItem(key, value)
  }
}