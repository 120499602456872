import classNames from 'classnames';
import React from 'react';
import { navigate } from 'gatsby';

import { NavProps } from '../Nav/Nav.component';
import { Text, TextFontSize } from '../Text';
import { Color } from '../../models/color';

import closeImg from '../../images/close.svg';

import './NavVertical.component.scss';

interface NavVertical extends NavProps {
  onClose: () => void;
}

const onClick = (activeRoute: string, path: string, onClose: () => void) => {
  const isActiveRoute = activeRoute === path;
  if (isActiveRoute) {
    onClose();
  } else {
    navigate(path);
    onClose();
  }
}

export const NavVertical: React.FC<NavVertical> = ({
  navItems,
  activeRoute,
  onClose,
}) => (
  <div className={'nav-vertical'}>
    <div className={'nav-vertical__inner'}>
      <div className={'nav-vertical__close'}
        onClick={onClose}
      >
        <img src={closeImg} />
      </div>
      <ul className={'nav-vertical__list'}>
        {navItems.map(({ label, path }) => (
          <li
            key={`nav-vertical-${label}`}
            className={classNames('nav-vertical__item', {
              'nav-vertical__item--active': activeRoute === path,
            })}
          >
            <div onClick={() => onClick(activeRoute, path, onClose)}>
              <Text
                size={TextFontSize.big}
                color={Color.WHITE}
              >
                {label}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
