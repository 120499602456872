import "./prism-theme.css"

import React from 'react';
import { StoreProvider } from './src/store/index';
import { Layout } from './src/containers/Layout';
import { Script } from 'gatsby'

import './src/styles/main.scss'

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Script>
        {
          `(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
            .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
            n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
            (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
            ml('account', '271910');`
        }
      </Script>
      <StoreProvider rootProps={props}>
        <Layout>
          {element}
        </Layout>
      </StoreProvider>
      </>
  )
};