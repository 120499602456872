export enum TextFontWeight { // TODO: Check weights for used font
  thin = 100,
  light = 300,
  regular = 400,
  medium = 500,
  bold = 700,
  black = 900,
}

export enum TextFontSize { // TODO: Check font sizes according to the project
  min = '10px',
  small = '12px',
  regular = '16px',
  big = '20px',
  huge = '30px',
  max = '40px',
}

export enum TextType {
  PARAGRAPH = 'parahraph',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  SPAN = 'span',
}
