import React from 'react';

import { TextFontSize, TextFontWeight, TextType } from './';
import { Color } from '../../models/color'

import './Text.component.scss';

export interface TextProps {
  children: React.ReactNode
  type?: TextType;
  size?: TextFontSize;
  weight?: TextFontWeight;
  color?: Color;
}

const getAttributes = (
  size: TextFontSize,
  weight: TextFontWeight,
  color?: Color,
) => ({
  className: 'text',
  style: {
    '--text-size': size,
    '--text-weight': weight,
    '--text-color': color,
  } as React.CSSProperties,
});

const renderText = (
  children: React.ReactNode,
  size: TextFontSize,
  type: TextType | undefined,
  weight: TextFontWeight,
  color?: Color,
) => {
  switch (type) {
    case TextType.PARAGRAPH:
      return <p {...getAttributes(size, weight, color)}>{ children }</p>
    case TextType.H1:
      return <h1 {...getAttributes(size, weight, color)}>{ children }</h1>
    case TextType.H2:
      return <h2 {...getAttributes(size, weight, color)}>{ children }</h2>
    case TextType.H3:
      return <h3 {...getAttributes(size, weight, color)}>{ children }</h3>
    case TextType.H4:
      return <h4 {...getAttributes(size, weight, color)}>{ children }</h4>
    default:
      return <span {...getAttributes(size, weight, color)}>{ children }</span>
  }
}

export const Text: React.FC<TextProps> = ({
  children,
  color,
  size = TextFontSize.regular,
  type,
  weight = TextFontWeight.regular,
}) => renderText(children, size, type, weight, color);
