import React from 'react';

import './GridRow.component.scss';

interface GridRowProps {
  children: React.ReactNode
  align?: 'center' | 'start' | 'end';
  justify?: 'space-between' | 'space-around' | 'start' | 'end';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}

export const GridRow: React.FC<GridRowProps> = ({
  align = 'center',
  children,
  justify = 'space-between',
  direction = 'row',
}) => (
  <div className={`grid-row grid-row--justify-${justify} grid-row--align-${align} grid-row--direction-${direction}`}>
    { children }
  </div>
);