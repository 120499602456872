import classNames from 'classnames';
import React from 'react';

import { Header } from '../../components/Header/Header.component';
import { useRoot } from '../../store/state/root/root.state';
import { useTheme } from '../../store/state/theme/theme.state';
import { Nav } from '../../components/Nav';
import { Grid } from '../../components/Grid';
import { Footer } from '../../components/Footer/Footer.component';

import './Layout.component.scss';
import { Cookies } from '../../components/Cookies/Cookies.component';

export const Layout: React.FC = ({ children }) => {
  const { state: { currentTheme, theme } } = useTheme();
  const { state: { location } } = useRoot();
  const isMainPage = location.pathname === '/';

  return (
    <div
      className={'layout'}
      style={{
        ...theme
      } as React.CSSProperties}
    >
      <div className={'layout__wrapper'}>
        <Grid.Wrapper>
          <div className={classNames('layout__header', {
            'layout__header--main': isMainPage,
          })}>
            <Header
              isMainPage={isMainPage}
              menu={(
                <Nav
                  navItems={[
                    {
                      label: 'Articles',
                      path: '/',
                    },
                    {
                      label: 'Contact',
                      path: '/contact',
                    }
                  ]}
                  activeRoute={location.pathname}
                  isMainPage={isMainPage}
                />
              )}
            />
          </div>
          <div className={'layout__content'}>
            { children }
          </div>
          <div className={'layout__footer'}>
            <Footer />
          </div>
        </Grid.Wrapper>
        </div>
        <Cookies />
    </div>
  );
};