import React from 'react';
import { StoreContext } from '../../model/store.model';

export type RootState = Partial<{
  location: Location;
  navigate: (to: string, options: {}) => any;
  pageContext: {}
  pageResources: {}
  params: {}
  path: string;
  pathContext: {}
  uri: string;
}>;

type RootContext = StoreContext<RootState, {}>;

const initialState: RootContext = {
  state: {},
  actions: undefined,
}

const RootContext = React.createContext<RootContext>(initialState);

export const RootProvider: React.FC<RootState> = ({ children, ...props }) => {
  return (
    <RootContext.Provider value={{
      state: {
        ...props
      },
      actions: {},
    }}>
      { children }
    </RootContext.Provider>
  )
};

export const useRoot = () => React.useContext(RootContext);
