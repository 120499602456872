exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-newsletter-policy-tsx": () => import("./../../../src/pages/newsletter-policy.tsx" /* webpackChunkName: "component---src-pages-newsletter-policy-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-article-template-article-template-component-tsx": () => import("./../../../src/templates/ArticleTemplate/ArticleTemplate.component.tsx" /* webpackChunkName: "component---src-templates-article-template-article-template-component-tsx" */),
  "component---src-templates-index-template-index-template-component-tsx": () => import("./../../../src/templates/IndexTemplate/IndexTemplate.component.tsx" /* webpackChunkName: "component---src-templates-index-template-index-template-component-tsx" */)
}

