import classNames from 'classnames';
import React from 'react';

import { Grid } from '../Grid';

import logoColor from '../../images/logo_color.svg';
import logoWhite from '../../images/logo_white.svg';

import './Header.component.scss';
import { Link } from 'gatsby';

interface HeaderProps {
  isMainPage: boolean;
  menu: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ isMainPage, menu }) => (
  <div className={classNames('header', {
    'header--main': isMainPage,
  })}>
    <Grid.Row>
      <div className={'header__logo'}>
        <Link to={'/'}>
          <img src={isMainPage ? logoWhite : logoColor} />
        </Link>
      </div>
      {menu}
    </Grid.Row>
  </div>
);