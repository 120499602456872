import classNames from 'classnames'
import React from 'react';

import './Hamburger.component.scss';

interface HamburgerProps {
  theme: 'default' | 'color';
  onClick: (...args: any[]) => void;
}

const renderLine = (isDefault: boolean) => isDefault
  ? <div className={'hamburger__line'} />
  : <div className={'hamburger__line hamburger__line--color'} />

export const Hamburger: React.FC<HamburgerProps> = ({ theme = 'default', onClick }) => (
  <div
    className={'hamburger'}
    onClick={onClick}
  >
    {renderLine(theme === 'default')}
    {renderLine(theme === 'default')}
    {renderLine(theme === 'default')}
  </div>
);
