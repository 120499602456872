import { DeviceType, DeviceResolution } from './window.model';

export const RESOLUTION: Record<DeviceType, DeviceResolution> = {
  [DeviceType.MOBILE]: {
    min: 320,
    max: 767,
  },
  [DeviceType.TABLET]: {
    min: 768,
    max: 1023,
  },
  [DeviceType.DESKTOP]: {
    min: 1024,
    max: 1979,
  },
  [DeviceType.HD]: {
    min: 1980,
    max: 10000,
  }
}