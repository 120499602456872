import React from 'react';
import { ThemeProvider, useTheme } from './state/theme/theme.state';
import { RootProvider, RootState } from './state/root/root.state';
import { WindowProvider } from './state/window/window.state';

interface StoreProviderProps {
  rootProps: RootState;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ rootProps, children }) => (
  <RootProvider {...rootProps}>
    <WindowProvider>
      <ThemeProvider>
        { children }
      </ThemeProvider>
    </WindowProvider>
  </RootProvider>
);

export const StoreHooks = {
  useTheme,
}