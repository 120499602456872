export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  HD = 'hd',
}

export interface DeviceResolution {
  min: number;
  max: number;
}

export interface WindowResolution {
  width: number;
  height: number;
}