import { Theme, ThemeColors } from "./theme.model";
import { Color } from "../../../models/color";

export const ThemeConfig: Record<Theme, ThemeColors> = {
  [Theme.LIGHT]: {
    '--theme-background': Color.LIGHTEST_GRAY,
    '--theme-background-break': Color.SILVER,
    '--theme-primary': Color.INTENSIVE_VIOLET,
    '--theme-primary-oposite': Color.WHITE,
    '--theme-secondary': Color.DARK_VIOLET,
    '--theme-third': Color.MINT,
    '--theme-title': Color.DARK_VIOLET,
    '--theme-paragraph': Color.DARK_GRAY,
    '--theme-accessories': Color.MIDDLE_GRAY,
    '--theme-codeBg': Color.LIGHT_GRAY,
    '--theme-codeContent': Color.MIDDLE_GRAY,
    '--theme-quote': Color.INTENSIVE_VIOLET,
    '--theme-box': Color.WHITE,
    '--theme-border': Color.LIGHT_GRAY,
  },
  [Theme.DARK]: {
    '--theme-background': Color.DARK_VIOLET,
    '--theme-background-break': Color.DARK_VIOLET,
    '--theme-primary': Color.INTENSIVE_VIOLET,
    '--theme-primary-oposite': Color.WHITE,
    '--theme-secondary': Color.MINT,
    '--theme-third': Color.MINT,
    '--theme-title': Color.INTENSIVE_VIOLET,
    '--theme-paragraph': Color.LIGHT_VIOLET,
    '--theme-accessories': Color.LIGHT_VIOLET,
    '--theme-codeBg': Color.MIDDLE_VIOLET,
    '--theme-codeContent': Color.INTENSIVE_VIOLET,
    '--theme-quote': Color.INTENSIVE_VIOLET,
    '--theme-box': Color.MIDDLE_VIOLET,
    '--theme-border': Color.MIDDLE_VIOLET,
  }
}