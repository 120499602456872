import { GridWrapper } from "./components/GridWrapper";
import { GridRow } from "./components/GridRow/GridRow.component";
import { GridFullWidth } from "./components/GridFullWidth/GridFullWidth.component";
import { GridColumn } from "./components/GridColumn/GridColumn.component";

export const Grid = {
  Column: GridColumn,
  FullWidth: GridFullWidth,
  Wrapper: GridWrapper,
  Row: GridRow,
};
