import React from 'react';

import { StoreContext } from '../../model/store.model';
import { Theme, ThemeColors } from './theme.model';
import { ThemeConfig } from './theme.config';

interface ThemeState {
  currentTheme: Theme;
  theme: ThemeColors;
}

interface ThemeActions {
  setCurrentTheme: React.Dispatch<React.SetStateAction<string | undefined>>,
}

type ThemeContext = StoreContext<ThemeState, ThemeActions>;

const initialState = {
  state: {
    currentTheme: Theme.LIGHT,
    theme: ThemeConfig[Theme.LIGHT],
  },
  actions: undefined,
}

const ThemeContext = React.createContext<ThemeContext>(initialState);

export const ThemeProvider: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = React.useState<Theme>(Theme.LIGHT);

  return (
    <ThemeContext.Provider value={{
      state: {
        currentTheme,
        theme: ThemeConfig[currentTheme],
      },
      actions: {
        setCurrentTheme
      },
    }}>
      { children }
    </ThemeContext.Provider>
  )
};

export const useTheme = () => React.useContext(ThemeContext);
